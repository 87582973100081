import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SUB_EDITION_FULL_FRAGMENT = `
  fragment subEditionFullFragment on SubEdition {
    uid
    schemaCode
    name
    code
    description
    autoFollow
    archived
    startTime
    startTimestamp
    endTime
    endTimestamp
    logoFileResource {
      ...fileResourceBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    _followerCount
    _bookmarkCount
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
